<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>Transaction Fee</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="mb-0 mt-2">
                                This transaction fee is charged by Unicorn Springs for every purchase transaction by this organization. The computed fee is presented to Stripe as the <code>application_fee</code>.
                            </p>

                            <template v-if="transactionFee">
                            <p class="mb-0 pb-0 mt-6">
                                {{ transactionFeeDisplay }}
                                <v-btn icon color="green darken-2" @click="dialogEditTransactionFee = true">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                                </v-btn>
                            </p>
                            <!-- <p class="mb-0 pb-0">
                                <TextLink :href="viewHostnameURL" target="_blank">{{ viewHostnameURL }}</TextLink>
                            </p> -->
                            </template>

                            <template v-if="!transactionFee">
                            <p class="mb-0 pb-0 mt-6">
                                No transaction fee.
                            </p>
                            <p class="mb-0 pb-0">
                                <v-btn icon color="green darken-2" @click="dialogEditTransactionFee = true">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                                </v-btn>
                            </p>
                            </template>
                        </v-card-text>
                    </v-card>
                    <v-dialog v-model="dialogEditTransactionFee" max-width="600">
                        <v-card class="pa-0" max-width="600">
                            <v-toolbar dense flat color="white">
                                <v-toolbar-title class="green--text text--darken-2">Set Transaction Fee</v-toolbar-title>
                            </v-toolbar>
                            <!-- <v-divider class="mx-5 mb-10"></v-divider> -->
                            <v-card-text>
                                <p>This change will be effective immediately.</p>
                            </v-card-text>
                            <v-form @submit.prevent="editTransactionFee" @keyup.enter.native="editTransactionFee" class="px-5 mt-5">
                                <v-simple-table>
                                    <template #default>
                                        <thead>
                                            <tr>
                                                <th>Percentage</th>
                                                <th>Flat amount</th>
                                                <th>Minimum</th>
                                                <th>Maximum</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <!-- <v-text-field v-model="editablePercentage" dense="dense" ref="editablePercentageInput" class="my-6"></v-text-field> -->
                                                    <InputPercentage v-model="editablePercentage" dense="dense" ref="editablePercentageInput" max-value="100" min-value="0" class="my-6"/>
                                                </td>
                                                <td>
                                                    <InputAmount v-model="editableFlatAmount" dense="dense" ref="editableFlatAmountInput" min-value="0" class="my-6"/>
                                                </td>
                                                <td>
                                                    <InputAmount v-model="editableMinimumAmount" dense="dense" ref="editableMinimumAmountInput" min-value="0" class="my-6"/>
                                                </td>
                                                <td>
                                                    <InputAmount v-model="editableMaximumAmount" dense="dense" ref="editableMaximumAmountInput" min-value="0" class="my-6"/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-form>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="green darken-2 white--text" @click="editTransactionFee" :disabled="!isEditTransactionFeeFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditTransactionFee = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
/* eslint-disable camelcase */
import { mapState } from 'vuex';
import { isValidUnsignedDecimalString, fromCurrencyAmountCDS, fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
import InputAmount from '@/components/InputAmount.vue';
import InputPercentage from '@/components/InputPercentage.vue';
// import { toNumber, toNumericString, toAmountExpPair } from '@/sdk/currency.js';

export default {
    components: {
        InputAmount,
        InputPercentage,
    },
    data: () => ({
        organization: null,
        hostname: null,
        error: null,

        dialogEditTransactionFee: false,
        editablePercentage: null,
        editableFlatAmount: null,
        editableMinimumAmount: null,
        editableMaximumAmount: null,
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditTransactionFeeFormComplete() {
            return this.editablePercentage !== null || this.editableFlatAmount !== null;
        },
        transactionFee() {
            return this.organization?.transaction_fee ?? {};
        },
        transactionFeeDisplay() {
            const transactionFee = this.organization?.transaction_fee;
            if (typeof transactionFee === 'undefined' || transactionFee === null) {
                return 'Undefined';
            }
            const parts = [];
            const {
                currency,
                // percentage_cds, // DEPRECATED, it shouldn't have been called _cds because it is a decimal string but not a CURRENCY decimal string, it's a PERCENTAGE decimal string
                // percentage, // new, uncomment after all organizations have been converted to store it like this
                flat_amount_csu,
                minimum_csu,
                maximum_csu,
            } = transactionFee;
            const percentage = transactionFee.percentage ?? transactionFee.percentage_cds; // TODO: when all organizations have been converted to store the value as 'percentage' instead of 'percentage_cds', this line can be removed and percentage can be uncommented above
            const isPercentage = isValidUnsignedDecimalString(percentage) && Number.parseFloat(percentage) > 0;
            const isFlatAmount = Number.isInteger(flat_amount_csu) && flat_amount_csu > 0;
            const isMinimum = Number.isInteger(minimum_csu) && minimum_csu > 0;
            const isMaximum = Number.isInteger(maximum_csu) && maximum_csu > 0;
            if (isPercentage) {
                parts.push(`${percentage}%`);
            }
            if (isPercentage && isFlatAmount) {
                parts.push(' + ');
            }
            if (isFlatAmount) {
                const flatAmount = fromCurrencyAmountCSU(currency, flat_amount_csu).toStringWithCurrencySymbol();
                parts.push(flatAmount); // e.g. $0.25
            }
            if (isMinimum || isMaximum) {
                parts.push(',');
            }
            if (isMinimum) {
                const minimumAmount = fromCurrencyAmountCSU(currency, minimum_csu).toStringWithCurrencySymbol();
                parts.push(` min ${minimumAmount}`);
            }
            if (isMaximum) {
                const maximumAmount = fromCurrencyAmountCSU(currency, maximum_csu).toStringWithCurrencySymbol();
                parts.push(` max ${maximumAmount}`);
            }
            return parts.join('');
        },
    },
    watch: {
        dialogEditTransactionFee(newValue) {
            if (newValue) {
                const percentage = this.organization.transaction_fee?.percentage ?? this.organization.transaction_fee?.percentage_cds; // TODO: when all organizations have been converted to store the value as 'percentage' instead of 'percentage_cds', this line can be removed and percentage can be uncommented above
                this.editablePercentage = isValidUnsignedDecimalString(percentage) ? percentage : null;
                this.editableFlatAmount = Number.isInteger(this.organization.transaction_fee?.flat_amount_csu) ? fromCurrencyAmountCSU(this.organization.transaction_fee.currency, this.organization.transaction_fee.flat_amount_csu).toString() : null;
                this.editableMinimumAmount = Number.isInteger(this.organization.transaction_fee?.minimum_csu) ? fromCurrencyAmountCSU(this.organization.transaction_fee.currency, this.organization.transaction_fee.minimum_csu).toString() : null;
                this.editableMaximumAmount = Number.isInteger(this.organization.transaction_fee?.maximum_csu) ? fromCurrencyAmountCSU(this.organization.transaction_fee.currency, this.organization.transaction_fee.maximum_csu).toString() : null;
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('editablePercentageInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            // more than one way to do it:
            // 1. inputRef.focus();
            // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
            // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
            if (typeof inputRef.focus === 'function') {
                inputRef.focus();
            } else {
                const inputElement = inputRef.$el.querySelector('input');
                inputElement.focus();
            }
        },
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        /*
        async loadSetting() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadSetting: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.get({ name: 'hostname' });
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.hostname = response.content;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadSetting: false });
            }
        },
        */
        async editTransactionFee() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                const currency = 'USD'; // TODO: allow organization to set this appropriately for their country, from list of supported currencies, and only when we have support for doing transactions in their selected currency
                const percentage = this.editablePercentage !== null && isValidUnsignedDecimalString(this.editablePercentage) ? this.editablePercentage : '0';
                const flat_amount_csu = this.editableFlatAmount !== null && isValidUnsignedDecimalString(this.editableFlatAmount) ? fromCurrencyAmountCDS(currency, this.editableFlatAmount).toCSU() : 0;
                const minimum_csu = this.editableMinimumAmount !== null && isValidUnsignedDecimalString(this.editableMinimumAmount) ? fromCurrencyAmountCDS(currency, this.editableMinimumAmount).toCSU() : null;
                const maximum_csu = this.editableMaximumAmount !== null && isValidUnsignedDecimalString(this.editableMaximumAmount) ? fromCurrencyAmountCDS(currency, this.editableMaximumAmount).toCSU() : null;
                const transactionFee = {
                    currency,
                    percentage,
                    flat_amount_csu,
                    minimum_csu,
                    maximum_csu,
                };
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { editTransactionFee: true });
                // const response = await this.$client.organization(this.$route.params.organizationId).setting.edit({ name: 'transaction_fee' }, { content: transactionFee });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.edit({ transaction_fee: transactionFee });
                console.log(`editTransactionFee: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.dialogEditTransactionFee = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    // this.loadSetting();
                    this.loadOrganization();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit transaction fee' });
                }
            } catch (err) {
                console.error('failed to edit Hostname', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit transaction fee' });
            } finally {
                this.$store.commit('loading', { editTransactionFee: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
        // this.loadSetting();
    },
};
</script>
