<template>
    <v-text-field v-model="editableText" :label="label" :hint="hint" :dense="dense" :error-messages="errorMessage">
        <template #prepend>
            $ <!-- TODO:  determine symbol from currency code like USD  ? or just put currency name after like  5  USD ?  and make currency display optional??-->
        </template>
    </v-text-field>
</template>

<script>
import { isValidNumeric, isValidPartialNumeric } from '@/sdk/input';

export default {
    props: ['value', 'dense', 'label', 'hint', 'maxValue', 'minValue'],
    data: () => ({
        editableText: null,
        cachedText: null,
        errorMessage: null,
    }),
    computed: {
        maxValueNumber() {
            let number;
            if (typeof this.maxValue === 'number') {
                number = this.maxValue;
            }
            if (typeof this.maxValue === 'string') {
                number = Number.parseFloat(this.maxValue);
            }
            if (Number.isFinite(number)) {
                return number;
            }
            return null;
        },
        minValueNumber() {
            let number;
            if (typeof this.minValue === 'number') {
                number = this.minValue;
            }
            if (typeof this.minValue === 'string') {
                number = Number.parseFloat(this.minValue);
            }
            if (Number.isFinite(number)) {
                return number;
            }
            return null;
        },
    },
    watch: {
        editableText(newValue) {
            this.checkInput(newValue);
        },
    },
    methods: {
        // activate(ref) {
        //     const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
        //     if (inputRef) {
        //         // more than one way to do it:
        //         // 1. inputRef.focus();
        //         // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
        //         // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
        //         inputRef.focus();
        //     }
        // },
        checkInput(newValue) {
            try {
                console.log(`checkInput newvalue: ${JSON.stringify(newValue)}`);
                if (newValue === null || newValue === '') {
                    this.errorMessage = null;
                    this.cachedText = null;
                    this.$emit('input', null);
                    return;
                }
                if (!isValidPartialNumeric(newValue)) {
                    console.log('checkInput: is NOT valid partial numeric');
                    this.errorMessage = ' ';
                    this.editableText = this.cachedText;
                    return;
                }
                if (isValidNumeric(newValue)) {
                    const numericValue = Number.parseFloat(newValue);
                    console.log(`checkInput: newvalue ${JSON.stringify(newValue)} numeric value ${JSON.stringify(numericValue)}`);
                    if (this.minValueNumber !== null && newValue < this.minValueNumber) {
                        console.log(`checkInput: value less than min ${this.minValueNumber}`);
                        this.errorMessage = ' ';
                        return;
                    }
                    if (this.maxValueNumber !== null && newValue > this.maxValueNumber) {
                        console.log(`checkInput: value more than max ${this.maxValueNumber}`);
                        this.errorMessage = ' ';
                        return;
                    }
                    this.cachedText = newValue;
                    this.errorMessage = null;
                    this.$emit('input', newValue);
                } else {
                    // user may still be typing, for example "0" -> "0." -> "0.1" so user might be at "0." which is a valid partial numeric but not a valid percentage yet, so we dont show any error but we also don't emit the new value
                    console.log('checkInput: is NOT valid numeric');
                    this.errorMessage = null;
                }
            } catch (err) {
                console.error('checkInput: errror', err);
                this.errorMessage = ' ';
                this.editableText = this.cachedText;
            }
        },
    },
    mounted() {
        this.editableText = this.value;
    },
};
</script>
